import clsx from 'clsx';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';
import styles from './styles.module.css';

interface Props {
  children?: ReactNode;
  type?: 'submit' | 'button';
  variant?: 'primary' | 'secondary' | 'text';
  className?: string;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  style?: object;
}
export type Ref = HTMLButtonElement;

const Button = forwardRef<Ref, Props>(
  (
    {
      type = 'button',
      children,
      variant = 'primary',
      className = '',
      loading = false,
      onClick,
      disabled = false,
      style,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled || loading}
        className={clsx(
          'font-medium focus:outline-none focus:ring focus:ring-opacity-75 transition-colors border-solid border',
          {
            'bg-primary hover:bg-primary-dark focus:ring-primary-light text-white border-transparent':
              variant === 'primary',
            'bg-white hover:bg-slate-200 focus:ring-[#3A3A3A] text-[#3A3A3A] border-[#9E9E9E]':
              variant === 'secondary',
            'focus:ring-[#3A3A3A] text-[#FFF] border-transparent':
              variant === 'text',
          },
          {
            'opacity-75 cursor-not-allowed hover:bg-inherit': disabled,
            'cursor-pointer': !disabled,
            'py-3 px-5 rounded-full': variant !== 'text',
          },
          className
        )}
        style={style}
        {...rest}
      >
        {loading ? (
          <span aria-label="Loading" className="relative leading-[0px]">
            <span
              className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
              aria-hidden="true"
            >
              <span className={styles.loader} aria-hidden="true" />
            </span>
            <span className="invisible" aria-hidden="true">
              {children}
            </span>
          </span>
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;
