'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

type AiFeaturesSectionPropsType = {
  isMobile: boolean;
};

export default function AIFeaturesSection({
  isMobile,
}: AiFeaturesSectionPropsType) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [topDistance, setTopDistance] = useState(200);
  const [wrapperHeight, setWrapperHeight] = useState(200);

  const handleScroll = () => {
    setTopDistance(
      (wrapperRef?.current?.getBoundingClientRect()?.top || 200) - 70
    ); // Remove navbar height
    setWrapperHeight(wrapperRef?.current?.offsetHeight || 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const percent = (topDistance / wrapperHeight) * 100;

  return (
    <div
      className="container items-center justify-center py-[2rem] lg:py-[3rem]"
      ref={wrapperRef}
    >
      <div className="flex flex-col text-center items-center">
        <h3 className="text-neutral-700 font-bold text-[1.25rem] lg:text-[2.25rem] pb-[0.25rem] lg:pb-[1rem]">
          AI-Powered <span className="text-royal-blue">Advanced</span> Features
        </h3>
        <p className="text-[#333] text-[1.25rem] pb-[2.5rem] max-w-[80rem] hidden lg:block">
          Level up your hiring game within 15 seconds
        </p>
        <p className="text-[#333] text-[0.87rem] pb-[1rem] block lg:hidden">
          Level up your hiring game within 15 seconds
        </p>
      </div>
      <div className="flex flex-col lg:flex-row mx-auto justify-stretch items-stretch gap-[1.5rem] font-semibold">
        {CARDS.map((card, index) => (
          <div
            key={card?.id}
            className="flex flex-col flex-1 h-[16rem] bg-white rounded-[10px] shadow items-start  p-[1.5rem] justify-evenly"
            style={{
              transform: !isMobile
                ? topDistance <= 0 || index === 1
                  ? 'translateX(0)'
                  : topDistance > wrapperHeight
                  ? index === 0
                    ? 'translateX(-50%)'
                    : 'translateX(50%)'
                  : index === 0
                  ? `translateX(-${percent}%)`
                  : `translateX(${percent}%)`
                : 'none',
              opacity: isMobile
                ? '100'
                : topDistance <= 0 || index === 1
                ? 1
                : topDistance > wrapperHeight
                ? 0
                : (100 - percent) / 100,
            }}
          >
            <div className="xs:w-28 lg:w-40  rounded-[5px] border border-pink-600 flex justify-between items-center px-2 py-2 lg:px-4 mb-[1.5rem]">
              <Image
                src="/images/ai-bot.svg"
                height={isMobile ? 16 : 22}
                width={isMobile ? 16 : 22}
                alt="AI Bot"
              />
              <div className="text-pink-600 xs:text-xs lg:text-base font-normal  leading-normal">
                AI-Powered
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              <p className="text-neutral-700 xs:text-base lg:text-[1.25rem] xs:mb-2 lg:mb-4 !leading-normal">
                {card?.title}
              </p>
              <p className="text-zinc-500 lg:text-[1.125rem] font-normal xs:text-xs ">
                {card?.description}
              </p>
            </div>
            <Link
              href={`/ai-features/${card.id + 1}`}
              className="text-blue-700 font-medium xs:text-sm lg:text-base mt-[1.45rem]"
            >
              Know More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

const CARDS = [
  {
    id: 0,
    title: 'Post a Job with a great description in less than 15 seconds!',
    description: 'while crafting a job post to hire',
    link: '#',
  },
  {
    id: 1,
    title: 'Prepare an Assessment Test with Minimal Clicks!',
    description: 'while creating relevant interview rounds',
    link: '#',
  },
  {
    id: 2,
    title: 'Ask AI Interview Prompts for more questions!',
    description: 'while interviewing a potential candidate',
    link: '#',
  },
];
