import Image from 'next/image';
import Link from 'next/link';

type PayperUseSectionPropsType = {
  isMobile: boolean;
};

export default function PayPerUseSection({
  isMobile,
}: PayperUseSectionPropsType) {
  return (
    <div className="container items-center justify-center py-[2rem] lg:py-[3rem]">
      <div className="w-full bg-gradient-to-br from-blue-600 to-transparent rounded-[20px] shadow lg:mt-24 flex justify-between items-center py-[1rem] xs:px-6 lg:py-[1.5rem] lg:px-[3.25rem] relative">
        <div className="w-5/6 ">
          <div className="w-[325.08px] text-white text-base font-semibold lg:text-4xl xl:text-5xl lg:font-bold mb-3 lg:mb-3">
            Pay Per Use!
          </div>
          <div className="text-white text-sm font-medium lg:text-3xl xl:text-4xl lg:font-semibold  mb-2 lg:mb-4">
            Economical value for real-time interview experience!
          </div>
          <div className=" text-white text-xs font-normal lg:text-xl xl:text-2xl lg:font-normal break-words mb-3 lg:mb-10">
            0% Worry on Contract/Lease/Hidden Fees, 100% Gain on Profile
            Visibility!
          </div>
          <Link
            href="pricing"
            className="px-[0.5rem] xs:px-6 xs:py-2 lg:px-8 lg:py-2.5 bg-white rounded-[32px] shadow justify-center items-center inline-flex whitespace-nowrap"
          >
            <div className="text-blue-700 xs:text-[8.25px] lg:text-base font-medium ">
              Know more
            </div>
          </Link>
        </div>
        <div className="w-1/6">
          <Image
            src={
              isMobile
                ? '/images/pay-per-use-mob.svg'
                : '/images/pay-per-use.svg'
            }
            width={432}
            height={270}
            alt="Pay per use"
            className=" xs:h-36 xs:w-36 lg:h-60 lg::w-60  lg:w-auto xs:absolute xs:bottom-5 lg:bottom-0 xs:-right-5 lg:-right-14 "
          />
        </div>
      </div>
    </div>
  );
}
