'use client';

import Image from 'next/image';

export default function FutureHiringSection() {
  return (
    <div className="max-w-[80vw] lg:max-w-[57rem] pb-0 p-[1.25rem] lg:p-[1.5rem] bg-gradient-to-b from-white to-transparent rounded-[10px]  flex justify-between items-center flex-col lg:flex-row border-none">
      <div className="flex-1 lg:pr-[2.75rem] lg:flex-col text-center lg:text-left pb-[2rem] lg:pb-0">
        <div className="text-neutral-700 text-[1.25rem] lg:text-[1.8rem] font-semibold  lg:leading-[57.60px]">
          Welcome to the <span className="text-blue-700">Future</span> of Hiring
        </div>
        <div className="text-[#676C72] text-[0.75rem] lg:text-lg font-normal lg:leading-9">
          Manage your jobs, candidates, panel, calendar, and feedbacks at one
          place!
        </div>
      </div>
      <Image
        src="/images/homepage-rocket.svg"
        height={101}
        width={101}
        alt="Rocket"
      />
    </div>
  );
}
