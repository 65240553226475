import Image from 'next/image';

type FlexibleSectionPropsType = {
  isMobile: boolean;
};

export default function FlexibleSection({
  isMobile,
}: FlexibleSectionPropsType) {
  return (
    <div className="container items-center justify-center py-[1rem] lg:py-[3rem] mt-8">
      <div className="w-full bg-gradient-to-br from-blue-600 to-transparent rounded-[20px] shadow flex justify-between items-stretch py-[1rem] px-[1rem] lg:py-[1.5rem] lg:px-[3.25rem]">
        <div className="flex-1 flex flex-col items-start">
          <div className="flex-1 flex flex-col justify-evenly gap-y-3">
            <div className="text-white text-[1rem] lg:text-3xl xl:text-5xl font-bold">
              Yes, it is flexible!
            </div>
            <div className="text-white xs:text-xs lg:text-2xl font-medium">
              Take interview from a Cube near you or Remote, we support either
              way!
            </div>
          </div>
        </div>
        <div>
          <Image
            src="/images/homepage-flex.svg"
            width={isMobile ? 101 : 180}
            height={isMobile ? 101 : 180}
            alt="Flex"
          />
        </div>
      </div>
    </div>
  );
}
