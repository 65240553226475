import Image from 'next/image';

type ProctoringSectionPropsTypes = {
  isMobile?: boolean;
};

export default function ProctoringSection({
  isMobile,
}: ProctoringSectionPropsTypes) {
  return (
    <div className="container flex flex-col lg:flex-row  flex-1 h-[16rem] bg-white rounded-[10px] shadow items-start justify-evenly my-[1rem] lg:my-[3rem] gap-[2rem] text-center lg:text-left">
      <div className="flex flex-1 flex-col items-stretch px-[2rem] py-[1rem] lg:px-[2rem] lg:py-[3rem]">
        <p className=" text-neutral-700 text-base lg:text-2xl lg:text-[#3A3A3A] font-semibold lg:font-bold">
          Interviewing Service (Proctoring)
        </p>
        <p className="text-xs lg:text-lg pt-[1rem] lg:pt-[1.37rem] text-[#82858A] max-w-[50rem] flex-1">
          If you require a professional proctor for strict remote examination
          monitoring, opt for Interview Cube&apos;s Proctoring functionality.
          You can count on our dedicated representative who will maintain the
          integrity and a common understanding of guidelines on behalf of the
          hiring company. Our assigned vigilant proctor will oversee the
          thorough assessment process and provide insightful feedback on the
          candidate&apos;s performance.
        </p>
        <p className="text-[#829BFF] text-[1.125rem] pt-[1rem] lg:pt-[1.81rem]">
          Join the Waitlist!
        </p>
      </div>
      <div className="pr-0 pt-0 lg:pr-[2rem] lg:pt-[3rem] xs:flex lg:flex-none xs:justify-center  xs:item-center xs:w-full lg:w-auto  mr-16">
        <Image
          src="/images/landing-page/lp-coming-soon-banner.svg"
          alt="Coming Soon"
          width={isMobile ? 224 : 258}
          height={isMobile ? 243 : 331}
          // className="h-full w-auto m-auto lg:m-0"
        />
      </div>
    </div>
  );
}
