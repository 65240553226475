'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

type MapSectionType = {
  handleSelectedCube: (val: any) => void;
  selectedCube: any;
  searchText: string;
  handleSearchText: (val: string) => void;
  searchCube: (e: any) => void;
  isMobile?: boolean;
};

export default function InterviewEnvSection({
  handleSelectedCube,
  selectedCube,
  searchText,
  handleSearchText,
  searchCube,
  isMobile,
}: MapSectionType) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [topDistance, setTopDistance] = useState(200);
  const [wrapperHeight, setWrapperHeight] = useState(200);
  const handleScroll = () => {
    setTopDistance(
      (wrapperRef?.current?.getBoundingClientRect()?.top || 200) - 70
    ); // Remove navbar height
    setWrapperHeight(wrapperRef?.current?.offsetHeight || 0);
  };

  useEffect(() => {
    if (!isMobile) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(function () {
      setCurrentSlideIndex((index) => {
        let newIndex = index + 1;
        if (newIndex >= SLIDES.length) {
          newIndex = 0;
        }
        return newIndex;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const currentSlide = SLIDES[currentSlideIndex];

  return (
    <div
      className="container items-center py-[1rem] lg:py-[3rem] re xs:mb-10 lg:mb-28"
      ref={wrapperRef}
      style={{
        transform: !isMobile
          ? `translateX(${
              topDistance <= 0
                ? 0
                : topDistance > wrapperHeight
                ? '-200%'
                : `-${(topDistance / wrapperHeight) * 100}%`
            })`
          : 'none',
      }}
    >
      <div className="flex flex-col lg:flex-row h-full ">
        <div className="flex-1 flex flex-col text-center lg:text-left lg:justify-center pb-[3rem] lg:pb-0 lg:pl-[1rem] relative ">
          <p className="text-black font-medium xs:text-[1.25rem] lg:text-[38px] pb-[0.25rem] lg:pb-6 lg:absolute lg:top-36 xs:font-semibold lg:font-medium xs:leading-10 lg:leading-0">
            Secure Interview Environment
          </p>
          <p className="text-royal-blue xs:text-base lg:text-2xl font-normal lg:font-medium lg:text-[1.5rem] pb-[0.86rem] lg:pb-3 lg:absolute lg:top-56">
            {currentSlide?.title}
          </p>
          <div className="lg:absolute lg:top-[17.25rem] xl:top-[16.75rem]">
            <div className="text-[#797979] xs:text-xs lg:text-[1.125rem] xs:mb-2 lg:mb-6">
              {currentSlide?.description}
            </div>
            <p className="text-[#797979] xs:text-xs lg:text-[1.125rem]">
              Reinvent interviewing experience with Cubes
              {isMobile && <br />}
              <Link
                href={currentSlide.link}
                className="text-royal-blue xs:text-xs lg:text-[1.125rem] pt-[0.25rem] lg:pt-3 ml-1 xs:font-medium lg:font-normal"
              >
                Know More
              </Link>
            </p>
          </div>

          <div className=" lg:w-[70%] xl:w-[50%]  hidden lg:block absolute lg:top-[32rem] xl:top-[29rem]">
            <form onSubmit={searchCube}>
              <input
                className="w-full h-[3.25rem] border-0 bg-white rounded-[90px] shadow-md px-[3.25rem] placeholder:text-[#C4C4C4]"
                type="text"
                placeholder="Find a cube near me"
                value={searchText}
                onChange={(e) => {
                  if (selectedCube) {
                    handleSelectedCube(null);
                  }
                  handleSearchText(e.target.value);
                }}
              />
              <button
                type="submit"
                className="bg-[#274CE0] rounded-[50%] h-[3.25rem] w-[3.25rem] absolute right-0 top-0 flex items-center justify-center"
              >
                <Image
                  height={36}
                  width={36}
                  src="/images/landing-page/lp-ic-search.svg"
                  alt="search"
                />
              </button>
            </form>
          </div>
        </div>
        <div className="flex-1 text-center relative flex flex-col items-center max-h-[400px]">
          <div className="z-10 relative flex items-center my-[1.25rem] lg:mt-[6rem] w-full  justify-center ">
            {SLIDES.map((slide, index) => (
              <Image
                key={slide?.id}
                src={slide?.img}
                alt="ID card"
                height={385}
                width={385}
                className={`h-auto w-auto transition-opacity ${
                  index === currentSlideIndex
                    ? 'opacity-1'
                    : 'opacity-0 absolute'
                }`}
              />
            ))}
          </div>
          <div className="flex w-[100%] lg:w-[70%] gap-[0.1rem] lg:gap-[0.4rem] mt-4">
            {SLIDES.map((slide, index) => (
              <span
                key={slide?.id}
                className={`flex flex-1 h-[0.2rem] lg:h-[0.4rem] rounded-lg transition-colors ${
                  currentSlideIndex === index ? 'bg-[#3D9AFF]' : 'bg-[#D9D9D9]'
                }`}
              />
            ))}
          </div>
        </div>
        <div className="relative w-[80%] mt-10 block lg:hidden mx-auto">
          <form onSubmit={searchCube}>
            <input
              className="w-full h-[2.36rem] bg-white rounded-[90px] shadow px-[2rem] placeholder:text-[#C4C4C4]"
              type="text"
              placeholder="Find a cube near me"
              value={searchText}
              onChange={(e) => {
                if (selectedCube) {
                  handleSelectedCube(null);
                }
                handleSearchText(e.target.value);
              }}
            />
            <button className="bg-[#274CE0] rounded-[50%] h-[2.36rem] w-[2.36rem] absolute right-0 top-0 flex items-center justify-center">
              <Image
                height={26}
                width={26}
                src="/images/landing-page/lp-ic-search.svg"
                alt="search"
              />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

const SLIDES = [
  {
    id: 0,
    title: 'Secure Interview Cubes Access',
    img: '/images/landing-page/lp-intr-env-secure.svg',
    description: (
      <div className="xs:leading-5 lg:leading-8">
        Modern access management enables a QR code or a URL for a candidate
        profile authentication fostering a paid and safe interviewing space to
        build stronger bonds of trust between candidates and recruiters!
      </div>
    ),
    link: '/cube',
  },
  {
    id: 1,
    title: 'Privacy',
    img: '/images/landing-page/lp-intr-env-privacy.svg',
    description: (
      <div className="xs:leading-5 lg:leading-8">
        Need to eliminate external distractions to get the perfect hire? Look
        out for a secluded environment for a focused interview environment with
        our Cubes.
      </div>
    ),
    link: '/cube#privacy',
  },
  {
    id: 2,
    title: 'Cost-Effectiveness',
    img: '/images/landing-page/lp-intr-env-cost.svg',
    description: (
      <div className="xs:leading-5 lg:leading-8">
        Seeking dynamic interview sessions without the hefty price tag? Look no
        further than our Cubes with cost-effective solutions!
      </div>
    ),
    link: '/cube#cost-eff',
  },
  {
    id: 3,
    title: 'Interviewer Confidence',
    img: '/images/landing-page/lp-intr-env-interviewer.svg',
    description: (
      <div className="xs:leading-5 lg:leading-8">
        Are you looking out for alternatives for in-person interviews as an
        interviewer? Consider our Cubes, designed to enhance your hiring
        confidence.
      </div>
    ),
    link: '/cube#interviewer',
  },
  {
    id: 4,
    title: 'Interviewee Confidence',
    img: '/images/landing-page/lp-intr-env-interviewee.svg',
    description: (
      <div className="xs:leading-5 lg:leading-8">
        In search of a private interview space to enhance your performance
        confidence? Step into Cubes to be the right hire.
      </div>
    ),
    link: '/cube#interviewee',
  },
  {
    id: 5,
    title: 'Scheduled Access for Candidates',
    img: '/images/landing-page/lp-intr-env-schedule.svg',
    description: (
      <div className="xs:leading-5 lg:leading-8">
        Feeling exhausted from the complexities of streamlining interviews
        seamlessly? Check out our advanced digital system optimizing time
        management for both recruiters and candidates.
      </div>
    ),
    link: '/cube#scheduled-access',
  },
  {
    id: 6,
    title: 'Scan to Join: Effective ID Validation Model',
    img: '/images/landing-page/lp-intr-env-scan.svg',
    description: (
      <div className="xs:leading-5 lg:leading-8">
        Why settle for uncertainty in candidate matching? Imagine confidently
        clicking, matching, and joining interview sessions with an ID validation
        model!
      </div>
    ),
    link: '/cube#scan',
  },
];
