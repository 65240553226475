'use client';

import { hasCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

export const CookiesConsent = () => {
  const [showConsent, setShowConsent] = useState<boolean>(false);

  useEffect(() => {
    setShowConsent(!hasCookie('interviewcube-cookie'));
  }, []);

  const acceptCookieConsent = () => {
    setCookie('interviewcube-cookie', 'true', {});
    setShowConsent(false);
  };

  const rejectCookieConsent = () => {
    setCookie('interviewcube-cookie', 'false', {});
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div className="fixed bottom-0 right-0 left-0 w-full bg-white py-5 px-5 md:px-20 z-99 flex flex-col md:flex-row items-center justify-between gap-x-5">
      <p className="w-full md:w-[80%] text-sm md:text-base">
        We use essential cookies to make our site work. With your consent, we
        may also use non-essential cookies to improve user experience and
        analyze website traffic. By clicking “Accept,” you agree to our
        website&apos;s cookie use as described in our{' '}
        <a href="/terms-privacy/cookie-policy" className="text-blue-700">
          Cookie Policy
        </a>
        .
      </p>
      <div className="flex items-center justify-end gap-x-3 flex-1 mt-3 md:mt-0">
        <button
          className="border border-neutral-700 rounded-sm py-1 px-2 w-24"
          onClick={rejectCookieConsent}
        >
          Reject All
        </button>
        <button
          className="text-white bg-blue-700 border border-blue-700 py-1 px-2"
          onClick={acceptCookieConsent}
        >
          Accept
        </button>
      </div>
    </div>
  );
};
