'use client';

import { CookiesConsent } from '@/src/components/CookiesConsent';
import { useEffect, useRef, useState } from 'react';
import AIFeaturesSection from './AIFeaturesSection';
import FlexibleSection from './FlexibleSection';
import FutureHiringSection from './FutureHiringSection';
import HeroSection from './HeroSection';
import HonestInterviewsSection from './HonestInterviewsSection';
import InterviewEnvSection from './InterviewEnvSection';
import KYCSection from './KYCSection';
import MapSection from './MapSection';
import { CITIES } from './MapSection/mapData';
import PayPerUseSection from './PayPerUseSection';
import ProctoringSection from './ProctoringSection';
import SmartFeaturesSection from './SmartFeaturesSection';
import VideoSection from './VideoSection';

export default function LandingHomePage() {
  const [selectedCube, setSelectedCube] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  const searchCube = (e: any) => {
    e.preventDefault();
    if (searchText) {
      const searchedCubeList = CITIES?.filter((city) =>
        city?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      setSelectedCube(searchedCubeList[0]);
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1024);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="h-full w-full max-w-[1728px] bg-[#f4f4f4] flex flex-col justify-center items-center">
      <HeroSection />
      <div className="h-full w-full flex  flex-col items-center bg-[#f4f4f4] xs:px-6 sm:px-24 ">
        <KYCSection isMobile={isMobile} />
        <InterviewEnvSection
          handleSelectedCube={setSelectedCube}
          selectedCube={selectedCube}
          searchText={searchText}
          handleSearchText={setSearchText}
          searchCube={searchCube}
          isMobile={isMobile}
        />
        <SmartFeaturesSection isMobile={isMobile} />
        <FutureHiringSection />
        <AIFeaturesSection isMobile={isMobile} />
        {/* <VideoSection isMobile={isMobile} /> */}
        <PayPerUseSection isMobile={isMobile} />
        <HonestInterviewsSection isMobile={isMobile} />
        <FlexibleSection isMobile={isMobile} />
        <MapSection
          handleSelectedCube={setSelectedCube}
          selectedCube={selectedCube}
          searchText={searchText}
          handleSearchText={setSearchText}
          searchCube={searchCube}
          mapRef={ref}
        />
        <ProctoringSection isMobile={isMobile} />
        <CookiesConsent />
      </div>
    </div>
  );
}
