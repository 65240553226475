export const MAP_REL_HEIGHT = 664.45;
export const MAP_REL_WIDTH = 1111;

export const CITIES = [
  { id: 2, name: 'San Francisco', top: 269.66, left: 1.58 },
  { id: 20, name: 'Washington DC', top: 100, left: 100 },
  { id: 10, name: 'Austin', top: 540, left: 500 },
  { id: 14, name: 'Atlanta', top: 470, left: 827.42 },
  { id: 11, name: 'Chicago', top: 218.31, left: 716.09 },
  { id: 25, name: 'Houston', top: 570, left: 570 },
  { id: 26, name: 'Dallas', top: 480, left: 540 },
  { id: 27, name: 'Phoenix', top: 440, left: 200 },
  // { id: 1, name: 'Sacramento', top: 223.44, left: 92.14 },
  // { id: 3, name: 'Bakersfield', top: 337.71, left: 58.76 },
  // { id: 4, name: 'Las Vegas', top: 355.68, left: 166.6 },
  // { id: 5, name: 'Los Angeles', top: 406.98, left: 60.04 },
  // { id: 6, name: 'Salt Lake City', top: 232.43, left: 261.61 },
  // { id: 7, name: 'Denver', top: 278.59, left: 377.15 },
  // { id: 8, name: 'Omaha', top: 250.35, left: 558.17 },
  // { id: 9, name: 'Oklahoma City', top: 396.71, left: 520.94 },
  // { id: 12, name: 'Indianapolis', top: 259.39, left: 753.32 },
  // { id: 13, name: 'Springfield', top: 286.3, left: 692.98 },
  // { id: 15, name: 'Orlando', top: 508.4, left: 909.95 },
  // { id: 16, name: 'Tampa', top: 544.35, left: 879.13 },
  // { id: 17, name: 'Miami', top: 613.73, left: 935.62 },
  // { id: 18, name: 'Detroit', top: 199.05, left: 826.5 },
  // { id: 19, name: 'Cleveland', top: 218.25, left: 856.03 },
  // { id: 21, name: 'Rochester', top: 169.52, left: 907.38 },
  // { id: 22, name: 'New York City', top: 132.05, left: 941.42 },
  // { id: 23, name: 'Boston', top: 165.05, left: 1077.42 },
  // { id: 24, name: 'Worcester', top: 193.05, left: 1022.42 },
];
