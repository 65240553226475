import { RefObject, useEffect } from 'react';

type useClickAwayType = {
  ref: RefObject<HTMLDivElement | HTMLButtonElement>;
  callback: () => void;
};

export const useClickAway = ({ ref, callback }: useClickAwayType) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(); //Do what you want to handle in the callback
      }
    };

    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  });
};
