'use client';

import Button from '@/src/_ui/atoms/Button';
import { useClickAway } from '@/src/hooks/useClickAway';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';
import { CITIES, MAP_REL_HEIGHT, MAP_REL_WIDTH } from './mapData';

type MapSectionType = {
  handleSelectedCube: (val: any) => void;
  selectedCube: any;
  searchText: string;
  handleSearchText: (val: string) => void;
  searchCube: (e: any) => void;
  mapRef: any;
};

export default function MapSection({
  handleSelectedCube,
  selectedCube,
  searchText,
  handleSearchText,
  searchCube,
  mapRef,
}: MapSectionType) {
  const [searchedCubeList, setSearchedCubeList] = useState<any>([]);
  const modalRef = useRef(null);
  const router = useRouter();

  const handleSelect = (cube: any) => {
    handleSelectedCube((c: any) => {
      if (c?.id === cube?.id) {
        return null;
      }
      return cube;
    });
    setSearchedCubeList([]);
  };

  useClickAway({
    ref: modalRef,
    callback: () => {
      handleSelectedCube(null);
      handleSearchText('');
      setSearchedCubeList([]);
    },
  });

  const handleNavigation = () => {
    router?.push('/contact-us');
  };

  const handleCubeSearch = (e: any) => {
    handleSearchText(e.target.value);
    if (e.target.value?.length) {
      const cubeList = CITIES?.filter((city) =>
        city?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      );
      setSearchedCubeList(cubeList);
    } else {
      setSearchedCubeList([]);
    }
  };

  return (
    <div
      className="container items-center justify-center py-[2rem] lg:py-[3rem]"
      ref={mapRef}
    >
      <div className="flex flex-col text-center items-center">
        <h3 className="text-[#1E1E1E] font-bold text-[1.25rem] lg:text-[2rem] pb-[0.25rem] lg:pb-[1rem]">
          Boosting Connectivity: Harnessing the Power of Cubes in Our Expanding
          Network
        </h3>
        <div className="text-neutral-500  lg:text-[#676C72] text-[0.75rem] lg:text-[1.25rem] pb-[2.5rem] max-w-[70rem]">
          We offer numerous cubes to book your slot for an engaging interview or
          a test in the following areas.{' '}
          <p>Find your nearest cube to tackle your employment obstacles.</p>
        </div>
        <div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full gap-5 mb-8">
          <div className="relative block">
            <form onSubmit={searchCube}>
              <input
                className="w-full h-[2.36rem] bg-white rounded-[90px] shadow px-[2rem] placeholder:text-[#C4C4C4]"
                type="text"
                placeholder="Find a cube near me"
                value={searchText}
                onChange={(e) => {
                  if (selectedCube) {
                    handleSelectedCube(null);
                  }
                  handleCubeSearch(e);
                }}
              />
              <button
                type="submit"
                className="bg-[#274CE0] rounded-[50%] h-[2.36rem] w-[2.36rem] absolute right-0 top-0 flex items-center justify-center"
                // onClick={searchCube}
              >
                <Image
                  height={26}
                  width={26}
                  src="/images/landing-page/lp-ic-search.svg"
                  alt="search"
                />
              </button>
            </form>
            {searchedCubeList?.length > 0 && (
              <div
                ref={modalRef}
                className="absolute top-full w-full flex flex-col items-start justify-start border border-blue-600 rounded-lg py-2 max-h-40 overflow-hidden overflow-y-auto bg-zinc-100 z-50"
              >
                {searchedCubeList?.map((cube: any) => {
                  return (
                    <p
                      key={cube?.id}
                      className="pl-8 py-1 w-full text-left hover:bg-zinc-200 cursor-pointer text-gray-400"
                      onClick={() => handleSelect(cube)}
                    >
                      {cube?.name}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <button
            className="px-8 py-2 bg-blue-700 text-white rounded-full text-base"
            onClick={() => handleNavigation()}
          >
            Suggest cube
          </button>
        </div>
        <div className="flex flex-1 w-full justify-center max-w-[70rem]">
          <div
            className="flex relative w-full"
            style={{
              paddingTop: `${(MAP_REL_HEIGHT / MAP_REL_WIDTH) * 100}%`,
              overflow: selectedCube ? 'hidden' : 'visible',
            }}
          >
            <div className="absolute inset-0">
              <div
                className="h-full w-full transition-all duration-500"
                style={{
                  scale: selectedCube ? '1.5' : '1',
                  transform: selectedCube
                    ? `translate(${
                        50 - (selectedCube?.left / MAP_REL_WIDTH) * 100
                      }%, ${50 - (selectedCube?.top / MAP_REL_HEIGHT) * 100}%)`
                    : '',
                }}
              >
                <Image
                  src="/images/landing-page/lp-map-bg.png"
                  width={2222}
                  height={1330}
                  alt="Map"
                  className="absolute inset-0"
                />
                {CITIES.map((cube) => (
                  <Button
                    key={cube?.id}
                    className="bg-primary rounded-full px-[0.25rem] py-[0.125rem] lg:px-[0.5rem] lg:py-[0.125rem] text-[0.5rem] lg:text-[1rem] absolute flex flex-nowrap whitespace-nowrap hover:z-10"
                    style={{
                      left: `${(cube?.left / MAP_REL_WIDTH) * 100}%`,
                      top: `${(cube?.top / MAP_REL_HEIGHT) * 100}%`,
                      zIndex: selectedCube ? 15 : '',
                    }}
                    onClick={() => handleSelect(cube)}
                  >
                    &#x2022; {cube?.name}
                  </Button>
                ))}
              </div>
            </div>
            {selectedCube?.id && (
              <div
                ref={modalRef}
                className="w-[320px] absolute top-[100%] left-[0%] lg:top-[50%] lg:left-[50%] translate-y-[-100%] flex flex-col items-stretch rounded-lg"
              >
                <div className="flex flex-col items-stretch rounded-lg border border-black bg-white mb-[0.5rem] xs:max-h-[11rem] md:max-h-full overflow-hidden">
                  <div className="w-[320px] h-[213px] object-cover relative">
                    <Image
                      src="/images/landing-page/dummy-test-center.jpg"
                      fill
                      alt="test center"
                    />
                  </div>
                  <div className="p-[1rem]">
                    <div className="flex items-center xs:text-sm flex-nowrap">
                      <p className="text-zinc-500  pr-[0.5rem] flex-nowrap">
                        Test Center:
                      </p>
                      <p>{selectedCube?.name}</p>
                    </div>
                    <div className="flex items-start  xs:text-sm pt-[0.5rem] flex-nowrap">
                      <p className="text-zinc-500 pr-[0.5rem]">Address:</p>
                      <p className="text-left">
                        118 4325, Mayfield Avanue, Los Angles, CS, 90025, USA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
